import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { APP_NAME } from '../../constants/env';

export default function Page() {
  const location = useLocation();
  const history = useHistory();
  const { slug } = useParams();

  const { result: pages } = useSelector((state) => state.page);

  const [isFetchingData, setIsFetchingData] = useState(true);
  const [page, setPage] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (pages.length > 0) {
      const selectedPage = pages.find((p) => p.url_slug === slug);
      if (!selectedPage) {
        history.push({ pathname: '/home' });
      } else {
        setPage(selectedPage);
      }
    }
  }, [slug, pages]);

  useEffect(() => {
    if (page) setIsFetchingData(false);
  }, [page]);

  if (isFetchingData) {
    return (
      <section className="flex flex-wrap justify-center py-10">
        <svg
          className="animate-spin -ml-1 mr-3 h-10 w-10 text-purple-800"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </section>
    );
  }
  return (
    <>
      <Helmet>
        <title>{`${page.meta_title} | ${APP_NAME}`}</title>
        <meta name="description" content={page.meta_description} />
        <meta name="keywords" content={page.meta_keywords} />

        <meta property="og:title" content={`${page.meta_title} | ${APP_NAME}`} />
        <meta property="og:description" content={page.meta_description} />
        <meta property="og:url" content={`${location.pathname}${location.search}`} />
        <meta name="twitter:title" content={`${page.meta_title} | ${APP_NAME}`} />
        <meta name="twitter:description" content={page.meta_description} />
      </Helmet>

      <section className="py-5 md:py-10 px-0 md:px-40 ck ck-content">
        <div dangerouslySetInnerHTML={{ __html: page.content }}></div>
      </section>
    </>
  );
}
