import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { PAYMENT_METHODS, SHIPPING_METHODS } from '../constants/footer';

const footerLogo = require('../assets/img/bstore.png');

export default function Footer() {
  const { result: settings } = useSelector((state) => state.settings);
  const { result: pages } = useSelector((state) => state.page);

  const featuredPages = pages.filter((p) => Number(p.show_in_footer) === 1);

  return (
    <>
      <footer>
        {/* FOOTER */}
        <div className="bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 py-14">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <img className="h-10 object-contain" src={footerLogo} alt="BSTORE" />
                <div className="mt-6">{settings?.footer}</div>
              </div>

              <div className="pl-0 md:pl-16">
                <div className="font-bold uppercase">Links</div>
                <div className="mt-6 grid grid-cols-2 gap-1 font-medium">
                  <div>
                    {featuredPages
                      .filter((page, index) => index < featuredPages.length / 2)
                      .map((page) => (
                        <div key={page.id} className="mb-3 hover:underline">
                          <Link to={`/article/${page.url_slug}`}>{page.title}</Link>
                        </div>
                      ))}
                  </div>
                  <div>
                    {featuredPages
                      .filter((page, index) => index >= featuredPages.length / 2)
                      .map((page) => (
                        <div key={page.id} className="mb-3 hover:underline">
                          <Link to={`/article/${page.url_slug}`}>{page.title}</Link>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div>
                <div className="font-bold uppercase">Payment Methods</div>
                <div className="mt-6">
                  {PAYMENT_METHODS.map((methods) => (
                    <div key={methods.type} className="flex">
                      {methods.methods.map((payment) => (
                        <img
                          key={payment.label}
                          src={payment.logo}
                          alt={payment.label}
                          className="w-16 h-auto mr-3 mb-1"
                        />
                      ))}
                    </div>
                  ))}
                </div>

                <div className="font-bold uppercase mt-5">Shipping Methods</div>
                <div className="mt-2 flex">
                  {SHIPPING_METHODS.map((shipping) => (
                    <img
                      key={shipping.label}
                      src={shipping.logo}
                      alt={shipping.label}
                      className="w-16 h-auto mr-3 mb-1"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* COPYRIGHT TEXT */}
        <div className="bg-purple-800">
          <div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="py-2 text-white text-center text-sm font-semibold">
              <div>Copyright &copy; {moment().format('Y')}. BSTORE. All Rights Reserved.</div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
