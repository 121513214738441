import {
  ADD_CART_TO_CHECKOUT,
  SET_IS_EDITING_USER_INFORMATION,
  SET_USER_INFORMATION,
  SET_SHIPPING_TYPE,
  SET_SHIPPING_WAREHOUSE,
  SET_SHIPPING_METHOD,
  SET_SHIPPING_METHOD_TYPE,
  APPLY_VOUCHER,
} from '../actions/_types';
import { SHIPPING_TYPES } from '../constants/data';

const initialState = {
  isEditingUserInformation: true,

  first_name: '',
  last_name: '',
  phone: '',

  user_address_id: null,
  shipping_first_name: '',
  shipping_last_name: '',
  shipping_phone: '',
  street_address: '',
  district_id: '',
  district: '',
  city_id: '',
  city: '',
  province_id: '',
  province: '',
  zip_code: '',

  total: 0,
  voucher_id: null,
  voucher_code: '',
  voucher_discount: 0,

  shipping_type: SHIPPING_TYPES.DELIVERY,
  warehouse_id: 1,
  shipping_method_id: null,
  shipping_method_name: '',
  shipping_method_detail: '',
  total_weight: 0,
  shipping_fee: 0,
  additional_shipping_fee: 0,
  total_shipping_fee: 0,
  insurance_fee_percentage: 0,
  insurance_fee_amount: 0,
  total_insurance_fee: 0,

  payment_fee: 0,
  grand_total: 0,

  voucher: null,
  warehouse: null,
  details: [], // time, product, variant, quantity, onlineStock, offlineStocks
};

export default function checkout(state = initialState, action) {
  const calculateWeightAndTotal = (carts) => {
    const weight = carts
      .map((c) => parseFloat(c.product.weight) * c.quantity)
      .reduce((a, b) => a + b, 0);
    const total = carts
      .map((c) => parseFloat(c.product.price) * c.quantity)
      .reduce((a, b) => a + b, 0);
    return {
      total_weight: weight,
      total,
      grand_total: total,
    };
  };

  const recalculateGrandTotal = (data) => {
    // Calculate Voucher
    if (data.voucher) {
      const { voucher } = data;
      const voucherDiscount =
        voucher.discount_type === 'Percentage'
          ? (parseFloat(voucher.discount_amount) / 100) * data.total
          : parseFloat(voucher.discount_amount);
      data.voucher_discount = Math.min(voucherDiscount, parseFloat(voucher.max_discount));
    }

    // Calculate Shipping Fee
    data.total_shipping_fee = data.shipping_fee + data.additional_shipping_fee;

    // Calculate Insurance Fee
    const totalAfterVoucher = data.total - data.voucher_discount;
    data.total_insurance_fee =
      (totalAfterVoucher * data.insurance_fee_percentage) / 100 + data.insurance_fee_amount;

    // Calculate Grand Total
    data.grand_total =
      totalAfterVoucher + data.total_shipping_fee + data.total_insurance_fee + data.payment_fee;

    return { ...data };
  };

  switch (action.type) {
    case ADD_CART_TO_CHECKOUT:
      return {
        ...initialState,
        details: action.result,
        ...calculateWeightAndTotal(action.result),
      };

    case SET_IS_EDITING_USER_INFORMATION:
      return {
        ...state,
        isEditingUserInformation: action.result,
      };

    case SET_USER_INFORMATION:
      return recalculateGrandTotal({
        ...state,
        ...action.result,
        shipping_method_id: '',
        shipping_method_name: '',
        shipping_method_detail: '',
        shipping_fee: 0,
        additional_shipping_fee: 0,
        total_shipping_fee: 0,
        insurance_fee_percentage: 0,
        insurance_fee_amount: 0,
        total_insurance_fee: 0,
      });

    case SET_SHIPPING_TYPE:
      return recalculateGrandTotal({
        ...state,
        shipping_type: action.result,
        shipping_method_id: '',
        shipping_method_name: '',
        shipping_method_detail: '',
        shipping_fee: 0,
        additional_shipping_fee: 0,
        total_shipping_fee: 0,
        insurance_fee_percentage: 0,
        insurance_fee_amount: 0,
        total_insurance_fee: 0,
      });

    case SET_SHIPPING_WAREHOUSE:
      return recalculateGrandTotal({
        ...state,
        warehouse: action.result,
        warehouse_id: action.result.id,
        shipping_method_id: '',
        shipping_method_name: '',
        shipping_method_detail: '',
        shipping_fee: 0,
        additional_shipping_fee: 0,
        total_shipping_fee: 0,
        insurance_fee_percentage: 0,
        insurance_fee_amount: 0,
        total_insurance_fee: 0,
      });

    case SET_SHIPPING_METHOD:
      return recalculateGrandTotal({
        ...state,
        ...action.result,
        shipping_method_detail: '',
        shipping_fee: 0,
      });

    case SET_SHIPPING_METHOD_TYPE:
      return recalculateGrandTotal({
        ...state,
        ...action.result,
      });

    case APPLY_VOUCHER:
      return recalculateGrandTotal({
        ...state,
        voucher_id: action.result.id,
        voucher_code: action.result.voucher_code,
        voucher_discount: 0,

        voucher: { ...action.result },
      });

    default:
      return state;
  }
}
