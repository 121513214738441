import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import { InlineTextInput, SubmitButton } from '../../../components/Base';
import { submitSignUp } from '../../../actions/auth';

export default function Register() {
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  const onSubmitForm = (data) => {
    if (data.password.trim() !== data.confirm_password.trim()) {
      Swal.fire('Submit Failed', 'Konfirmasi Password tidak sesuai', 'error');
      return;
    }

    setIsSubmittingForm(true);
    dispatch(submitSignUp(data))
      .then(() => history.push('/sign-in'))
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <>
      <div className="flex justify-center px-4">
        <div className="max-w-xl w-full mb-40 pt-10">
          <div>
            <h2 className="text-center text-3xl font-extrabold text-gray-900">
              Create Your Account
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              {'Already have an Account? '}
              <Link to="/sign-in" className="font-medium text-blue-500 hover:text-blue-700">
                Login Here
              </Link>
            </p>
          </div>

          <form className="mt-8" onSubmit={handleSubmit(onSubmitForm)}>
            <InlineTextInput
              type="phone"
              title="Phone No"
              inputRef={register({ required: true })}
              name="phone"
              errorMessage={errors.phone && 'Kolom Phone No harus diisi'}
            />

            <InlineTextInput
              type="text"
              title="First Name"
              inputRef={register({ required: true })}
              name="first_name"
              errorMessage={errors.first_name && 'Kolom First Name harus diisi'}
            />

            <InlineTextInput
              type="text"
              title="Last Name"
              inputRef={register({ required: true })}
              name="last_name"
              errorMessage={errors.last_name && 'Kolom Last Name harus diisi'}
            />

            <InlineTextInput
              type="password"
              title="Password"
              inputRef={register({ required: true })}
              name="password"
              errorMessage={errors.password && 'Kolom Password harus diisi'}
            />

            <InlineTextInput
              type="password"
              title="Confirm Password"
              inputRef={register({ required: true })}
              name="confirm_password"
              errorMessage={errors.confirm_password && 'Kolom Confirm Password harus diisi'}
            />

            <div className="mb-4">
              <div className="flex items-center">
                <input
                  ref={register({ required: true })}
                  id="terms-checkbox"
                  name="terms_checkbox"
                  type="checkbox"
                  value=""
                  className="w-5 h-5"
                />
                <label for="terms-checkbox" className="ml-2 text-sm leading-tight font-semibold">
                  Saya telah membaca dan menyetujui{' '}
                  <a
                    href={`${window.location.origin}/article/terms-and-conditions`}
                    target="_blank"
                    className="font-semibold text-purple-600 hover:text-purple-800 hover:underline transition-all"
                    rel="noreferrer">
                    Syarat dan Ketentuan
                  </a>{' '}
                  penggunaan website dari BSTORE
                </label>
              </div>
              {errors.terms_checkbox && (
                <span className="text-xs text-red-500">
                  Anda harus menyetujui Syarat dan Ketentuan untuk melanjutkan registrasi
                </span>
              )}
            </div>

            <div className="mt-8">
              <SubmitButton
                additionalClass="w-full"
                type="submit"
                text="Create Account"
                textClass="text-white text-xs"
                isLoading={isSubmittingForm}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
